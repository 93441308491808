<template>
  <div class="" v-if="product">
    <el-form :model="product" :rules="rules" ref="form">
      <TopBarTitleComponent title="Productos" :showBackIcon="true">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonDeleteComponent
              class="mr-4"
              @onConfirmDelete="onConfirmDelete"
            />
            <ButtonPrimaryComponent
              title="Actualizar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>

      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <div class="card box-shadow">
              <div class="card-body">
                <FileUploadComponent
                  title="Subir imagen"
                  :showFileList="false"
                  :preview="product.image"
                  @fileBase64="onFileBase64"
                />
                <el-form-item prop="name">
                  <slot name="label">
                    <label class="font-semi-bold m-0">
                      Nombre del Servicio / Producto
                    </label>
                  </slot>
                  <el-input
                    type="text"
                    :disabled="product.cod_main_product != 0"
                    v-model="product.name"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Código/SKU</label>
                  </slot>
                  <el-input type="text" v-model="product.code"></el-input>
                </el-form-item>
                <el-form-item prop="amount">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Precio</label>
                  </slot>
                  <InputAmount
                    :amount="product.amount"
                    @onChange="amount => onInputAmount(amount, 'amount')"
                  />
                </el-form-item>
                <el-form-item prop="taxes_id">
                  <slot name="label">
                    <label class="font-semi-bold m-0">IVA</label>
                  </slot>
                  <SelectTaxes
                    :setTaxesId="product.taxes_id"
                    @onSelectTax="onSelectTax"
                  />
                </el-form-item>
                <el-form-item
                  prop="categories_id"
                  v-if="product.cod_main_product == 0"
                >
                  <SelectCategories
                    :setSelectCategories="product.categories_id"
                    @onChangeSelect="onChangeSelectCategories"
                  />
                </el-form-item>
                <el-form-item prop="description">
                  <slot name="label">
                    <label class="font-semi-bold m-0">
                      Descripción de la categoría
                      <span class="text-muted">
                        (detalle a visualizar en los documentos)
                      </span></label
                    >
                  </slot>
                  <InputTextComponent
                    maxlength="null"
                    :showWordLimit="false"
                    :value="product.description"
                    @onInputChange="onInputChange"
                  />
                </el-form-item>
              </div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <div class="card box-shadow mb-4">
              <div class="card-body">
                <h5
                  class="h5 text-color-primary font-semi-bold line-bottom pb-2"
                >
                  Tipo de Producto
                </h5>
                <el-form-item prop="type">
                  <el-select
                    v-model="product.type"
                    placeholder="Seleccionar"
                    disabled
                  >
                    <el-option
                      v-for="(option, index) in productTypes"
                      :key="index"
                      :label="option.label"
                      :value="option.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="card box-shadow mb-4">
              <div class="card-body">
                <h5
                  class="h5 text-color-primary font-semi-bold line-bottom pb-2"
                >
                  Estado de Producto
                </h5>
                <el-form-item prop="status_product">
                  <SwitchStatus
                    :setValue.sync="product.status_product"
                    @onStatus="onStatusProduct"
                  />
                </el-form-item>
              </div>
            </div>

            <TransitionFadeComponent>
              <div class="card box-shadow mb-4">
                <div class="card-body">
                  <h5
                    class="h5 text-color-primary font-semi-bold line-bottom pb-2"
                  >
                    Gestión de inventario
                  </h5>
                  <TransitionFadeComponent
                    v-for="(stock, index) in product.stocks"
                    :key="index"
                  >
                    <el-form-item prop="stock" v-if="product.is_stock_counter">
                      <slot name="label">
                        <label class="font-semi-bold m-0">
                          {{ stock.establishment }}
                        </label>
                      </slot>
                      <el-input-number
                        :controls="false"
                        placeholder="Cantidad"
                        v-model="product.stocks[index].quantity"
                      ></el-input-number>
                    </el-form-item>
                  </TransitionFadeComponent>

                  <el-form-item prop="is_stock_counter">
                    <SwitchTracking
                      :setValue.sync="product.is_stock_counter"
                      @onTracking="onTrackingProduct"
                    />
                  </el-form-item>
                </div>
              </div>
            </TransitionFadeComponent>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../components/TopBarTitle";
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import ButtonDeleteComponent from "../../../components/ButtonDelete";
import FileUploadComponent from "../../../components/FileUpload";
import InputTextComponent from "../../../components/InputText";
import SelectTaxes from "./SelectTaxes";
import SelectCategories from "../../../components/SelectCategories";
import TransitionFadeComponent from "@/components/Transitions/Fade";
import SwitchTracking from "./SwitchTracking";
import SwitchStatus from "./SwitchStatus";
import InputAmount from "@/components/General/InputAmount";

import Validate from "@/assets/validate";
import catalogueService, {
  productTypes
} from "../../services/catalogueService";

export default {
  name: "ProductEditComponent",
  data: () => ({
    productTypes,
    product: null,
    apiResponse: {
      message: null
    },
    rules: {
      name: [Validate.rules.required()],
      code: [Validate.rules.required()],
      taxes_id: [Validate.rules.required("change")],
      amount: [Validate.rules.numeric("number")]
      // categories_id: [Validate.rules.required("change")]
      // stock: [],
      // description: [Validate.rules.required()]
    }
  }),
  computed: {},
  methods: {
    onFileBase64(base64) {
      this.product.image = base64;
    },
    onChangeInput(data) {
      this.product = { ...this.product, ...data };
    },
    onInputAmount({ amount }, key) {
      this.product[key] = amount;
    },
    onInputChange(value) {
      this.product.description = value;
    },
    onTrackingProduct({ tracking }) {
      this.product.is_stock_counter = tracking;
    },
    onStatusProduct({ status }) {
      this.product.status_product = status;
    },
    onChangeSelectCategories({ selectCategories }) {
      this.product.categories_id = selectCategories;
    },
    onSelectTax({ id }) {
      this.product.taxes_id = id;
    },
    onSaveVariations({ variations }) {
      this.product.variations = variations;
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await catalogueService
              .updateProductId(this.product)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message, product } = response.data;
            if (success) {
              this.product = product;
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async handleGetProductId(productId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await catalogueService
          .getProductId(productId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response.data.product) {
          this.product = response.data.product;
        }
      } catch (error) {
        return false;
      }
    },
    async onConfirmDelete() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await catalogueService
          .deleteProductId(this.product.id)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, product } = response.data;
        if (success) {
          this.product = product;
          this.$notifications.success({
            message
          });
        }
        if (response.data.success) {
          this.$router.push({ name: "catalogue.products" });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    ButtonPrimaryComponent,
    ButtonDeleteComponent,
    FileUploadComponent,
    InputTextComponent,
    //ProductVariations,
    SelectCategories,
    TransitionFadeComponent,
    SwitchTracking,
    SwitchStatus,
    InputAmount,
    SelectTaxes
  },
  mounted() {
    const { id: productId } = this.$route.params;
    if (productId) this.handleGetProductId(productId);
  }
};
</script>

<style></style>
